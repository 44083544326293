import s from './Footer.module.css';

const Footer = () => {
    return(
        <footer className={s.footer}>
            <div className='container'>
                <div className={s.logo}>
                    <img src='/img/GK_logo.png' alt='GK logo'></img>
                </div>
                <div className={s.text}>
                    <span>ФОП "Кордолевський Андрій"</span>
                    <span>© 2023 Grankord. All rights reserved.</span>
                </div>
            </div>
        </footer>
    )
}

export default Footer;