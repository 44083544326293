import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Catalog from './components/main/Catalog';
import Main from './components/main/Main';
import Contact from './components/main/Contact';
import SP1 from './components/main/Single/SP1';
import SP2 from './components/main/Single/SP2';
import SP3 from './components/main/Single/SP3';
import SP4 from './components/main/Single/SP4';
import SP5 from './components/main/Single/SP5';
import SP6 from './components/main/Single/SP6';
import SP7 from './components/main/Single/SP7';
import DP1 from './components/main/Double/DP1';
import DP2 from './components/main/Double/DP2';
import DP3 from './components/main/Double/DP3';
import DP4 from './components/main/Double/DP4';
import RP1 from './components/main/RedStone/RP1';
import RP2 from './components/main/RedStone/RP2';
import CP1 from './components/main/Compleks/CP1';
import BP1 from './components/main/Baby/BP1';
import CrossP1 from './components/main/Cross/CrossP1'
import IP1 from './components/main/Icons/IP1';
import IP2 from './components/main/Icons/IP2';
import BallsP1 from './components/main/Balls/BallsP1';


function App() {
  return (
    <BrowserRouter>
      <div className='wrapper'>
        <Header />
        <main>
        <Routes>
          <Route path='/' element={<Main />} />
          <Route path='/catalog' element={<Catalog />} />
          {/* Single */}
          <Route path='/single_page1/*' element={<SP1 />} />
          <Route path='/single_page2/*' element={<SP2 />} />
          <Route path='/single_page3/*' element={<SP3 />} />
          <Route path='/single_page4/*' element={<SP4 />} />
          <Route path='/single_page5/*' element={<SP5 />} />
          <Route path='/single_page6/*' element={<SP6 />} />
          <Route path='/single_page7/*' element={<SP7 />} />
          {/* Double */}
          <Route path='/double_page1/*' element={<DP1 />} />
          <Route path='/double_page2/*' element={<DP2 />} />
          <Route path='/double_page3/*' element={<DP3 />} />
          <Route path='/double_page4/*' element={<DP4 />} />
          {/* RedStone */}
          <Route path='/red-stone_page1/*' element={<RP1 />} />
          <Route path='/red-stone_page2/*' element={<RP2 />} />
          {/* Compleks */}
          <Route path='/compleks_page1/*' element={<CP1 />} />
          {/* Baby */}
          <Route path='/baby_page1/*' element={<BP1 />} />
          {/* Icons */}
          <Route path='/icons-page1/*' element={<IP1 />} />
          <Route path='/icons-page2/*' element={<IP2 />} />
          <Route path='/cross_page1/*' element={<CrossP1 />} />
          <Route path='/balls_page1/*' element={<BallsP1 />} />
          <Route path='/contact/*' element={<Contact />} />
        </Routes>
        </main>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;
