import { NavLink } from 'react-router-dom';
import Semple from '../../holders/Sample';
import Title from '../../holders/Title'
import { scrollTo } from '../../../fuction';

let page = '/single_page2'

const SP2 = () => {
    return(
        <div className='container'>
            <Title title="Одинарні пам'ятники" subtitle="Вишукані комплекти одинарних пам'ятників для пам'яті близьких."/>
            <div className='windows_block'>
                <Semple page={page} title="MS0025" photo='/img/single/MS0025.png'/>
                <Semple page={page} title="MS0026" photo='/img/single/MS0026.png'/>
                <Semple page={page} title="MS0027" photo='/img/single/MS0027.png'/>
                <Semple page={page} title="MS0028" photo='/img/single/MS0028.png'/>
                <Semple page={page} title="MS0029" photo='/img/single/MS0029.png'/>
                <Semple page={page} title="MS0030" photo='/img/single/MS0030.png'/>
                <Semple page={page} title="MS0031" photo='/img/single/MS0031.png'/>
                <Semple page={page} title="MS0032" photo='/img/single/MS0032.png'/>
                <Semple page={page} title="MS0033" photo='/img/single/MS0033.png'/>
                <Semple page={page} title="MS0034" photo='/img/single/MS0034.png'/>
                <Semple page={page} title="MS0035" photo='/img/single/MS0035.png'/>
                <Semple page={page} title="MS0036" photo='/img/single/MS0036.png'/>
                <Semple page={page} title="MS0037" photo='/img/single/MS0037.png'/>
                <Semple page={page} title="MS0038" photo='/img/single/MS0038.png'/>
                <Semple page={page} title="MS0039" photo='/img/single/MS0039.png'/>
                <Semple page={page} title="MS0040" photo='/img/single/MS0040.png'/>
                <Semple page={page} title="MS0041" photo='/img/single/MS0041.png'/>
                <Semple page={page} title="MS0042" photo='/img/single/MS0042.png'/>
                <Semple page={page} title="MS0043" photo='/img/single/MS0043.png'/>
                <Semple page={page} title="MS0044" photo='/img/single/MS0044.png'/>
                <Semple page={page} title="MS0045" photo='/img/single/MS0045.png'/>
                <Semple page={page} title="MS0046" photo='/img/single/MS0046.png'/>
                <Semple page={page} title="MS0047" photo='/img/single/MS0047.png'/>
                <Semple page={page} title="MS0048" photo='/img/single/MS0048.png'/>
            </div>
            <div className='windows_navbar'>
                <NavLink to='/single_page1'>1</NavLink>
                <NavLink to='/single_page2' onClick={scrollTo()} className='active'>2</NavLink>
                <NavLink to='/single_page3'>3</NavLink>
                <NavLink to='/single_page4'>4</NavLink>
                <NavLink to='/single_page5'>5</NavLink>
                <NavLink to='/single_page6'>6</NavLink>
                <NavLink to='/single_page7'>7</NavLink>
            </div>
        </div>
    )
}

export default SP2;