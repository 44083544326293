import s from './Banner.module.css';

const Banner = (props) => {
    return (
        <div className={s.banner}>
            <div className={s.content}>
                <div className={s.logo}>
                    <img src='/img/GK_logo.png' alt='logo'></img>
                </div>
                <div className={s.companyName}>
                    <span>GranKord</span>
                </div>
                <div className={s.slogan}>
                    <span>Гранітні вироби на віки</span>
                </div>
            </div>
        </div>
    )
}

export default Banner