import { NavLink } from 'react-router-dom';
import Semple from '../../holders/Sample';
import Title from '../../holders/Title';
import { scrollTo } from '../../../fuction';

let page = '/single_page4';

const SP4 = () => {
    return(
        <div className='container'>
            <Title title="Одинарні пам'ятники" subtitle="Вишукані комплекти одинарних пам'ятників для пам'яті близьких."/>
            <div className='windows_block'>
                <Semple page={page} title="MS0073" photo='/img/single/MS0073.png'/>
                <Semple page={page} title="MS0074" photo='/img/single/MS0074.png'/>
                <Semple page={page} title="MS0075" photo='/img/single/MS0075.png'/>
                <Semple page={page} title="MS0076" photo='/img/single/MS0076.png'/>
                <Semple page={page} title="MS0077" photo='/img/single/MS0077.png'/>
                <Semple page={page} title="MS0078" photo='/img/single/MS0078.png'/>
                <Semple page={page} title="MS0079" photo='/img/single/MS0079.png'/>
                <Semple page={page} title="MS0080" photo='/img/single/MS0080.png'/>
                <Semple page={page} title="MS0081" photo='/img/single/MS0081.png'/>
                <Semple page={page} title="MS0082" photo='/img/single/MS0082.png'/>
                <Semple page={page} title="MS0083" photo='/img/single/MS0083.png'/>
                <Semple page={page} title="MS0084" photo='/img/single/MS0084.png'/>
                <Semple page={page} title="MS0085" photo='/img/single/MS0085.png'/>
                <Semple page={page} title="MS0086" photo='/img/single/MS0086.png'/>
                <Semple page={page} title="MS0087" photo='/img/single/MS0087.png'/>
                <Semple page={page} title="MS0088" photo='/img/single/MS0088.png'/>
                <Semple page={page} title="MS0089" photo='/img/single/MS0089.png'/>
                <Semple page={page} title="MS0090" photo='/img/single/MS0090.png'/>
                <Semple page={page} title="MS0091" photo='/img/single/MS0091.png'/>
                <Semple page={page} title="MS0092" photo='/img/single/MS0092.png'/>  
                <Semple page={page} title="MS0093" photo='/img/single/MS0093.png'/>  
                <Semple page={page} title="MS0094" photo='/img/single/MS0094.png'/>  
                <Semple page={page} title="MS0095" photo='/img/single/MS0095.png'/>  
                <Semple page={page} title="MS0096" photo='/img/single/MS0096.png'/> 
            </div>
            <div className='windows_navbar'>
                <NavLink to='/single_page1'>1</NavLink>
                <NavLink to='/single_page2'>2</NavLink>
                <NavLink to='/single_page3'>3</NavLink>
                <NavLink to='/single_page4' onClick={scrollTo()} className='active'>4</NavLink>
                <NavLink to='/single_page5'>5</NavLink>
                <NavLink to='/single_page6'>6</NavLink>
                <NavLink to='/single_page7'>7</NavLink>
            </div>
        </div>
    )
}

export default SP4;