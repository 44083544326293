import Category from '../holders/Category';
import Title from '../holders/Title';

const Product = () => {
    return(
        <div className='container'>
            <Title title='Наша Продукція' subtitle="Мистецтво, яке залишає слід у часі: Дізнайтесь більше про нашу унікальну гратнітну продукцію"/>
            <div className='windows_block catalog'>
                <Category link='/single_page1' title="Одинарні пам'ятники" photo='/img/category/single.png'/>
                <Category link='/double_page1' title="Подвійні пам'ятники" photo='/img/category/double.png'/>
                <Category link='/red-stone_page1' title="З червоним каменем" photo='/img/category/red.png'/>
                <Category link='/compleks_page1' title="Комплекси" photo='/img/category/complecs.png'/>
                <Category link='/baby_page1' title="Дитячі пам'ятники" photo='/img/category/baby.png'/>
                <Category link='/balls_page1' title="Шари, вази, болясини" photo='/img/category/bolasun.png'/>
                <Category link='/icons-page1' title="Ікони епітафії" photo='/img/category/icon.png'/>
                <Category link='/cross_page1' title="Хрести" photo='/img/category/cross.png'/>
            </div>
        </div>
    )
}

export default Product;