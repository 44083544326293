import { NavLink } from 'react-router-dom';
import Semple from '../../holders/Sample';
import Title from '../../holders/Title';
import { scrollTo } from '../../../fuction';

let page = '/single_page7';

const SP7 = () => {
    return(
        <div className='container'>
            <Title title="Одинарні пам'ятники" subtitle="Вишукані комплекти одинарних пам'ятників для пам'яті близьких."/>
            <div className='windows_block'>
                <Semple page={page} title="MS0145" photo='/img/single/MS0145.png'/>
                <Semple page={page} title="MS0146" photo='/img/single/MS0146.png'/>
                <Semple page={page} title="MS0147" photo='/img/single/MS0147.png'/>
                <Semple page={page} title="MS0148" photo='/img/single/MS0148.png'/>
                <Semple page={page} title="MS0149" photo='/img/single/MS0149.png'/>
                <Semple page={page} title="MS0150" photo='/img/single/MS0150.png'/>
                <Semple page={page} title="MS0151" photo='/img/single/MS0151.png'/>
                <Semple page={page} title="MS0152" photo='/img/single/MS0152.png'/>
                <Semple page={page} title="MS0153" photo='/img/single/MS0153.png'/>
                <Semple page={page} title="MS0154" photo='/img/single/MS0154.png'/>
                <Semple page={page} title="MS0155" photo='/img/single/MS0155.png'/>
                <Semple page={page} title="MS0156" photo='/img/single/MS0156.png'/>
                <Semple page={page} title="MS0157" photo='/img/single/MS0157.png'/>
                <Semple page={page} title="MS0158" photo='/img/single/MS0158.png'/>
                <Semple page={page} title="MS0159" photo='/img/single/MS0159.png'/>
                <Semple page={page} title="MS0160" photo='/img/single/MS0160.png'/>
                <Semple page={page} title="MS0161" photo='/img/single/MS0161.png'/>
                <Semple page={page} title="MS0162" photo='/img/single/MS0162.png'/>
                <Semple page={page} title="MS0163" photo='/img/single/MS0163.png'/>
                <Semple page={page} title="MS0164" photo='/img/single/MS0164.png'/>
                <Semple page={page} title="MS0165" photo='/img/single/MS0165.png'/>
                <Semple page={page} title="MS0166" photo='/img/single/MS0166.png'/>
                <Semple page={page} title="MS0167" photo='/img/single/MS0167.png'/>
                <Semple page={page} title="MS0168" photo='/img/single/MS0168.png'/>
            </div>
            <div className='windows_navbar'>
                <NavLink to='/single_page1'>1</NavLink>
                <NavLink to='/single_page2'>2</NavLink>
                <NavLink to='/single_page3'>3</NavLink>
                <NavLink to='/single_page4'>4</NavLink>
                <NavLink to='/single_page5'>5</NavLink>
                <NavLink to='/single_page6'>6</NavLink>
                <NavLink to='/single_page7' onClick={scrollTo()} className='active'>7</NavLink>
            </div>
        </div>
    )
}

export default SP7;