import { NavLink } from 'react-router-dom';
import Semple from '../../holders/Sample';
import Title from '../../holders/Title';
import { scrollTo } from '../../../fuction';

let page = '/double_page2'

const DP1 = () => {
    return(
        <div className='container'>
            <Title title="Подвійні пам'ятники" subtitle=""/>
            <div className='windows_block'>
                <Semple page={page} title="MD0025" photo='/img/double/MD0025.png'/>
                <Semple page={page} title="MD0026" photo='/img/double/MD0026.png'/>
                <Semple page={page} title="MD0027" photo='/img/double/MD0027.png'/>
                <Semple page={page} title="MD0028" photo='/img/double/MD0028.png'/>
                <Semple page={page} title="MD0029" photo='/img/double/MD0029.png'/>
                <Semple page={page} title="MD0030" photo='/img/double/MD0030.png'/>
                <Semple page={page} title="MD0031" photo='/img/double/MD0031.png'/>
                <Semple page={page} title="MD0032" photo='/img/double/MD0032.png'/>
                <Semple page={page} title="MD0033" photo='/img/double/MD0033.png'/>
                <Semple page={page} title="MD0034" photo='/img/double/MD0034.png'/>
                <Semple page={page} title="MD0035" photo='/img/double/MD0035.png'/>
                <Semple page={page} title="MD0036" photo='/img/double/MD0036.png'/>
                <Semple page={page} title="MD0037" photo='/img/double/MD0037.png'/>
                <Semple page={page} title="MD0038" photo='/img/double/MD0038.png'/>
                <Semple page={page} title="MD0039" photo='/img/double/MD0039.png'/>
                <Semple page={page} title="MD0040" photo='/img/double/MD0040.png'/>
                <Semple page={page} title="MD0041" photo='/img/double/MD0041.png'/>
                <Semple page={page} title="MD0042" photo='/img/double/MD0042.png'/>
                <Semple page={page} title="MD0043" photo='/img/double/MD0043.png'/>
                <Semple page={page} title="MD0044" photo='/img/double/MD0044.png'/>
                <Semple page={page} title="MD0045" photo='/img/double/MD0045.png'/>
                <Semple page={page} title="MD0046" photo='/img/double/MD0046.png'/>
                <Semple page={page} title="MD0047" photo='/img/double/MD0047.png'/>
                <Semple page={page} title="MD0048" photo='/img/double/MD0048.png'/>
            </div>
            <div className='windows_navbar'>
                <NavLink to='/double_page1'>1</NavLink>
                <NavLink to='/double_page2' onClick={scrollTo()} className='active'>2</NavLink>
                <NavLink to='/double_page3'>3</NavLink>
                <NavLink to='/double_page4'>4</NavLink>
            </div>
        </div>
    )
}

export default DP1;