import { NavLink } from 'react-router-dom';
import Semple from '../../holders/Sample';
import Title from '../../holders/Title';
import { scrollTo } from '../../../fuction';

let page = '/single_page6';

const SP6 = () => {
    return(
        <div className='container'>
            <Title title="Одинарні пам'ятники" subtitle="Вишукані комплекти одинарних пам'ятників для пам'яті близьких."/>
            <div className='windows_block'>
                <Semple page={page} title="MS0121" photo='/img/single/MS0121.png'/>  
                <Semple page={page} title="MS0122" photo='/img/single/MS0122.png'/>  
                <Semple page={page} title="MS0123" photo='/img/single/MS0123.png'/>  
                <Semple page={page} title="MS0124" photo='/img/single/MS0124.png'/>  
                <Semple page={page} title="MS0125" photo='/img/single/MS0125.png'/>  
                <Semple page={page} title="MS0126" photo='/img/single/MS0126.png'/>  
                <Semple page={page} title="MS0127" photo='/img/single/MS0127.png'/>  
                <Semple page={page} title="MS0128" photo='/img/single/MS0128.png'/>  
                <Semple page={page} title="MS0129" photo='/img/single/MS0129.png'/>  
                <Semple page={page} title="MS0130" photo='/img/single/MS0130.png'/>
                <Semple page={page} title="MS0131" photo='/img/single/MS0131.png'/>
                <Semple page={page} title="MS0132" photo='/img/single/MS0132.png'/>
                <Semple page={page} title="MS0133" photo='/img/single/MS0133.png'/>
                <Semple page={page} title="MS0134" photo='/img/single/MS0134.png'/>
                <Semple page={page} title="MS0135" photo='/img/single/MS0135.png'/>
                <Semple page={page} title="MS0136" photo='/img/single/MS0136.png'/>
                <Semple page={page} title="MS0137" photo='/img/single/MS0137.png'/>
                <Semple page={page} title="MS0138" photo='/img/single/MS0138.png'/>
                <Semple page={page} title="MS0139" photo='/img/single/MS0139.png'/>
                <Semple page={page} title="MS0140" photo='/img/single/MS0140.png'/>
                <Semple page={page} title="MS0141" photo='/img/single/MS0141.png'/>
                <Semple page={page} title="MS0142" photo='/img/single/MS0142.png'/>
                <Semple page={page} title="MS0143" photo='/img/single/MS0143.png'/>
                <Semple page={page} title="MS0144" photo='/img/single/MS0144.png'/>
            </div>
            <div className='windows_navbar'>
                <NavLink to='/single_page1'>1</NavLink>
                <NavLink to='/single_page2'>2</NavLink>
                <NavLink to='/single_page3'>3</NavLink>
                <NavLink to='/single_page4'>4</NavLink>
                <NavLink to='/single_page5'>5</NavLink>
                <NavLink to='/single_page6' onClick={scrollTo()} className='active'>6</NavLink>
                <NavLink to='/single_page7'>7</NavLink>
            </div>
        </div>
    )
}

export default SP6;