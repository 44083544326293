import { NavLink } from 'react-router-dom';
import Semple from '../../holders/Sample';
import Title from '../../holders/Title';
import { scrollTo } from '../../../fuction';

let page = '/double_page1'

const DP1 = () => {
    return(
        <div className='container'>
            <Title title="Подвійні пам'ятники" subtitle=""/>
            <div className='windows_block'>
                <Semple page={page} title="MD0001" photo='/img/double/MD0001.png'/>
                <Semple page={page} title="MD0002" photo='/img/double/MD0002.png'/>
                <Semple page={page} title="MD0003" photo='/img/double/MD0003.png'/>
                <Semple page={page} title="MD0004" photo='/img/double/MD0004.png'/>
                <Semple page={page} title="MD0005" photo='/img/double/MD0005.png'/>
                <Semple page={page} title="MD0006" photo='/img/double/MD0006.png'/>
                <Semple page={page} title="MD0007" photo='/img/double/MD0007.png'/>
                <Semple page={page} title="MD0008" photo='/img/double/MD0008.png'/>
                <Semple page={page} title="MD0009" photo='/img/double/MD0009.png'/>
                <Semple page={page} title="MD0010" photo='/img/double/MD0010.png'/>
                <Semple page={page} title="MD0011" photo='/img/double/MD0011.png'/>
                <Semple page={page} title="MD0012" photo='/img/double/MD0012.png'/>
                <Semple page={page} title="MD0013" photo='/img/double/MD0013.png'/>
                <Semple page={page} title="MD0014" photo='/img/double/MD0014.png'/>
                <Semple page={page} title="MD0015" photo='/img/double/MD0015.png'/>
                <Semple page={page} title="MD0016" photo='/img/double/MD0016.png'/>
                <Semple page={page} title="MD0017" photo='/img/double/MD0017.png'/>
                <Semple page={page} title="MD0018" photo='/img/double/MD0018.png'/>
                <Semple page={page} title="MD0019" photo='/img/double/MD0019.png'/>
                <Semple page={page} title="MD0020" photo='/img/double/MD0020.png'/>
                <Semple page={page} title="MD0021" photo='/img/double/MD0021.png'/>
                <Semple page={page} title="MD0022" photo='/img/double/MD0022.png'/>
                <Semple page={page} title="MD0023" photo='/img/double/MD0023.png'/>
                <Semple page={page} title="MD0024" photo='/img/double/MD0024.png'/>
            </div>
            <div className='windows_navbar'>
                <NavLink to='/double_page1' onClick={scrollTo()} className='active'>1</NavLink>
                <NavLink to='/double_page2'>2</NavLink>
                <NavLink to='/double_page3'>3</NavLink>
                <NavLink to='/double_page4'>4</NavLink>
            </div>
        </div>
    )
}

export default DP1;