import { NavLink } from 'react-router-dom';
import s from './Category.module.css';
import { scrollTo } from '../../fuction';

const Category = (props) => {
    return (
        <NavLink to={props.link} onClick={scrollTo()}>
        <div className={s.holder}>
            <div className={s.photo}>
                <img src={props.photo} alt={props.title}></img>
            </div>
            <div className={s.title}>
                <span>{props.title}</span>
            </div>
        </div>
        </NavLink>
    )
}

export default Category