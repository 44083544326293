import { NavLink } from 'react-router-dom';
import Semple from '../../holders/Sample';
import Title from '../../holders/Title';
import { scrollTo } from '../../../fuction';

let page = '/icons_page1'

const SP1 = () => {
    return(
        <div className='container'>
           <Title title="Ікони епітафії" subtitle=""/>
            <div className='windows_block'>
            <Semple title="20210609_092022" photo='/img/icons/IMG_20210609_092022.png'/>
                <Semple page={page} title="20210609_092044" photo='/img/icons/IMG_20210609_092044.png'/>
                <Semple page={page} title="20210609_092123" photo='/img/icons/IMG_20210609_092123.png'/>
                <Semple page={page} title="20210609_092248" photo='/img/icons/IMG_20210609_092248.png'/>
                <Semple page={page} title="20210629_094208" photo='/img/icons/IMG_20210629_094208.png'/>
                <Semple page={page} title="20210629_094213" photo='/img/icons/IMG_20210629_094213.png'/>
                <Semple page={page} title="20210629_094223" photo='/img/icons/IMG_20210629_094223.png'/>
                <Semple page={page} title="20210629_094221" photo='/img/icons/IMG_20210629_094221.png'/>
                <Semple page={page} title="20210629_094230" photo='/img/icons/IMG_20210629_094230.png'/>
                <Semple page={page} title="20210629_094232" photo='/img/icons/IMG_20210629_094232.png'/>
                <Semple page={page} title="20210629_094240" photo='/img/icons/IMG_20210629_094240.png'/>
                <Semple page={page} title="20230208_132154" photo='/img/icons/IMG_20230208_132154.png'/>
                <Semple page={page} title="20230621_093052" photo='/img/icons/IMG_20230621_093052.png'/>
                <Semple page={page} title="20230621_093108" photo='/img/icons/IMG_20230621_093108.png'/>
                <Semple page={page} title="20230621_093115" photo='/img/icons/IMG_20230621_093115.png'/>
                <Semple page={page} title="20230621_093128" photo='/img/icons/IMG_20230621_093128.png'/>
                <Semple page={page} title="20230621_093134" photo='/img/icons/IMG_20230621_093134.png'/>
                <Semple page={page} title="20230621_093149" photo='/img/icons/IMG_20230621_093149.png'/>
                <Semple page={page} title="20230621_093211" photo='/img/icons/IMG_20230621_093211.png'/>
                <Semple page={page} title="20230621_093220" photo='/img/icons/IMG_20230621_093220.png'/>
                <Semple page={page} title="1a21308973b5581" photo='/img/icons/IMG-1a21308973b5581499fb427ecd819250-V.png'/>
                <Semple page={page} title="2f131f24e68faa7" photo='/img/icons/IMG-2f131f24e68faa7da1727c8c39143c9e-V.png'/>
                <Semple page={page} title="8eaa167e1106b74" photo='/img/icons/IMG-8eaa167e1106b7497bb22f884f02d868-V.png'/>
                <Semple page={page} title="69b3eaabff8b460" photo='/img/icons/IMG-69b3eaabff8b46038ea4302527556b8f-V.png'/>
            </div>
            <div className='windows_navbar'>
                <NavLink to='/icons_page1' onClick={scrollTo()} ClassName='active'>1</NavLink>
                <NavLink to='/icons_page2'>2</NavLink>
            </div>
        </div>
    )
}

export default SP1;