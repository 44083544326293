import { NavLink } from 'react-router-dom';
import Semple from '../../holders/Sample';
import Title from '../../holders/Title';
import { scrollTo } from '../../../fuction';

let page = '/balls_page1';

const BallsP1 = () => {
    return(
        <div className='container'>
            <Title title="Шари, вази, болясини" subtitle=""/>
            <div className='windows_block'>
                <Semple page={page} title="20210129_213535" photo='/img/balls/IMG_20210129_213535.png'/>
                <Semple page={page} title="20210205_083550" photo='/img/balls/IMG_20210205_083550.png'/>
                <Semple page={page} title="20210629_094150" photo='/img/balls/IMG_20210629_094150.png'/>
                <Semple page={page} title="20210629_094247" photo='/img/balls/IMG_20210629_094247.png'/>
                <Semple page={page} title="20210731_122741" photo='/img/balls/IMG_20210731_122741.png'/>
                <Semple page={page} title="20211124_092110" photo='/img/balls/IMG_20211124_092110.png'/>
                <Semple page={page} title="20230202_121050" photo='/img/balls/IMG_20230202_121050.png'/>
                <Semple page={page} title="20230228_105702" photo='/img/balls/IMG_20230228_105702.png'/>
                <Semple page={page} title="06-45-16-435" photo='/img/balls/Screenshot_2021-08-26-06-45-16-435_com.facebook.katana.png'/>
                <Semple page={page} title="лампадка" photo='/img/balls/лампадка красная.png'/>
            </div>
            <div className='windows_navbar'>
                <NavLink to='/balls_page1' onClick={scrollTo()} className='active'>1</NavLink>
            </div>
        </div>
    )
}

export default BallsP1;