import s from './Title.module.css';

const Title = (props) => {
    return (
        <div className={s.title}>
            <h1 className={s.title_main}>{props.title}</h1>
            <div className={s.hLine}></div>
            <h2 className={s.title_subtitle}>{props.subtitle}</h2>
        </div>
    ); 
}

export default Title;