import { NavLink } from 'react-router-dom';
import Semple from '../../holders/Sample';
import Title from '../../holders/Title';
import { scrollTo } from '../../../fuction';

let page = '/compleks_page1';

const CP1 = () => {
    return(
        <div className='container'>
            <Title title="Комплекси" subtitle=""/>
            <div className='windows_block'>
                <Semple page={page} title="MC0001" photo='/img/compleks/MC0001.png'/>
                <Semple page={page} title="MC0002" photo='/img/compleks/MC0002.png'/>
                <Semple page={page} title="MC0003" photo='/img/compleks/MC0003.png'/>
                <Semple page={page} title="MC0004" photo='/img/compleks/MC0004.png'/>
                <Semple page={page} title="MC0005" photo='/img/compleks/MC0005.png'/>
                <Semple page={page} title="MC0007" photo='/img/compleks/MC0007.png'/>
                <Semple page={page} title="MC0008" photo='/img/compleks/MC0008.png'/>
                <Semple page={page} title="MC0009" photo='/img/compleks/MC0009.png'/>
                <Semple page={page} title="MC0010" photo='/img/compleks/MC0010.png'/>
                <Semple page={page} title="MC0011" photo='/img/compleks/MC0011.png'/>
                <Semple page={page} title="MC0012" photo='/img/compleks/MC0012.png'/>
                <Semple page={page} title="MC0013" photo='/img/compleks/MC0013.png'/>
                <Semple page={page} title="MC0014" photo='/img/compleks/MC0014.png'/>
                <Semple page={page} title="MC0015" photo='/img/compleks/MC0015.png'/>
                <Semple page={page} title="MC0016" photo='/img/compleks/MC0016.png'/>
                <Semple page={page} title="MC0017" photo='/img/compleks/MC0017.png'/>
                <Semple page={page} title="MC0018" photo='/img/compleks/MC0018.png'/>
                <Semple page={page} title="MC0019" photo='/img/compleks/MC0019.png'/>
            </div>
            <div className='windows_navbar'>
                <NavLink to='/compleks_page1' onClick={scrollTo()} className='active'>1</NavLink>
            </div>
        </div>
    )
}

export default CP1;