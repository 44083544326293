import { NavLink } from 'react-router-dom';
import Semple from '../../holders/Sample';
import Title from '../../holders/Title';
import { scrollTo } from '../../../fuction';

let page = '/icons_page1'

const SP1 = () => {
    return(
        <div className='container'>
           <Title title="Ікони епітафії" subtitle=""/>
            <div className='windows_block'>
                <Semple page={page} title="2702e6d8eb0e4c6" photo='/img/icons/IMG-2702e6d8eb0e4c65932e11af919b9a21-V.png'/>
                <Semple page={page} title="6796db6cdaeccba" photo='/img/icons/IMG-6796db6cdaeccbad06216429e576622c-V.png'/>
                <Semple page={page} title="36863a4466cb3bc" photo='/img/icons/IMG-36863a4466cb3bc1a865868931859344-V.png'/>
                <Semple page={page} title="75953ea03312e80" photo='/img/icons/IMG-75953ea03312e800debb773ba96985fb-V.png'/>
                <Semple page={page} title="b81c387beeba156" photo='/img/icons/IMG-b81c387beeba15689bba7d4208e20d2f-V.png'/>
                <Semple page={page} title="cf7ae6029123bab" photo='/img/icons/IMG-cf7ae6029123bab1757de6011b07e507-V.png'/>
                <Semple page={page} title="da251780e1cd01d" photo='/img/icons/IMG-da251780e1cd01d1c346b2fa740395be-V.png'/>
                <Semple page={page} title="e4289999d5792bf" photo='/img/icons/IMG-e4289999d5792bf73a52935cc133dc01-V.png'/>
                <Semple page={page} title="18-58-44-110" photo='/img/icons/Screenshot_2023-06-25-18-58-44-110_com.viber.voip.png'/>
            </div>
            <div className='windows_navbar'>
                <NavLink to='/icons_page1'>1</NavLink>
                <NavLink to='/icons_page2' onClick={scrollTo()} ClassName='active'>1</NavLink>
            </div>
        </div>
    )
}

export default SP1;