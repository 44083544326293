import s from './Adventeges.module.css'
import Title from '../holders/Title';

const Advantages = () => {
    return(
        <div className='container'>
            <Title title='Наші переваги' subtitle='Декілька причин чому обирають нас!' />
            <div className={s.adv_container}>
                <div className={s.item}>
                    <div className={s.item_image}>
                        <img src='/img/nice_price.png' alt='Найкраща ціна'></img>
                    </div>
                    <div className={s.item_text}>
                        <span className={s.title}>НАЙКРАЩА ЦІНА</span>
                        <span className={s.subtitle}>Ми є виробником своєї продукції, тому купюючи у нас ви економите як мінімум 30%, минаючи посередників.</span>
                    </div>
                </div>
                <div className={s.item}>
                    <div className={s.item_image}>
                        <img src='/img/individual.png' alt='Індивідуальний підхід'></img>
                    </div>
                    <div className={s.item_text}>
                        <span className={s.title}>ІНДИВІДУАЛЬНИЙ ПІДХІД</span>
                        <span className={s.subtitle}>Ми вислухаємо усі побажання та виготовимо продукцію саме такую якою ви її бачите.</span>
                    </div>
                </div>
                <div className={s.item}>
                    <div className={s.item_image}>
                        <img src='/img/quality.png' alt='Якість'></img>
                    </div>
                    <div className={s.item_text}>
                        <span className={s.title}>ВІДМІННА ЯКІСТЬ</span>
                        <span className={s.subtitle}>Найкращі матеріали та професійна обробка дарують якість на віки.</span>
                    </div>
                </div>
                <div className={s.item}>
                    <div className={s.item_image}>
                        <img src='/img/garantie.png' alt='Гарантія'></img>
                    </div>
                    <div className={s.item_text}>
                        <span className={s.title}>Гарантія</span>
                        <span className={s.subtitle}>На всю продукцію та роботи ми надаємо гарантію!</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Advantages;