import { NavLink } from 'react-router-dom';
import Semple from '../../holders/Sample';
import Title from '../../holders/Title';
import { scrollTo } from '../../../fuction';

let page = '/single_page3';

const SP3 = () => {
    return(
        <div className='container'>
            <Title title="Одинарні пам'ятники" subtitle="Вишукані комплекти одинарних пам'ятників для пам'яті близьких."/>
            <div className='windows_block'>
                <Semple page={page} title="MS0049" photo='/img/single/MS0049.png'/>
                <Semple page={page} title="MS0050" photo='/img/single/MS0050.png'/>
                <Semple page={page} title="MS0051" photo='/img/single/MS0051.png'/>
                <Semple page={page} title="MS0052" photo='/img/single/MS0052.png'/>
                <Semple page={page} title="MS0053" photo='/img/single/MS0053.png'/>
                <Semple page={page} title="MS0054" photo='/img/single/MS0054.png'/>
                <Semple page={page} title="MS0055" photo='/img/single/MS0055.png'/>
                <Semple page={page} title="MS0056" photo='/img/single/MS0056.png'/>
                <Semple page={page} title="MS0057" photo='/img/single/MS0057.png'/>
                <Semple page={page} title="MS0058" photo='/img/single/MS0058.png'/>
                <Semple page={page} title="MS0059" photo='/img/single/MS0059.png'/>
                <Semple page={page} title="MS0060" photo='/img/single/MS0060.png'/>
                <Semple page={page} title="MS0061" photo='/img/single/MS0061.png'/>
                <Semple page={page} title="MS0062" photo='/img/single/MS0062.png'/>
                <Semple page={page} title="MS0063" photo='/img/single/MS0063.png'/>
                <Semple page={page} title="MS0064" photo='/img/single/MS0064.png'/>
                <Semple page={page} title="MS0065" photo='/img/single/MS0065.png'/>
                <Semple page={page} title="MS0066" photo='/img/single/MS0066.png'/>
                <Semple page={page} title="MS0067" photo='/img/single/MS0067.png'/>
                <Semple page={page} title="MS0068" photo='/img/single/MS0068.png'/>
                <Semple page={page} title="MS0069" photo='/img/single/MS0069.png'/>
                <Semple page={page} title="MS0070" photo='/img/single/MS0070.png'/>
                <Semple page={page} title="MS0071" photo='/img/single/MS0071.png'/>
                <Semple page={page} title="MS0072" photo='/img/single/MS0072.png'/>
            </div>
            <div className='windows_navbar'>
                <NavLink to='/single_page1'>1</NavLink>
                <NavLink to='/single_page2'>2</NavLink>
                <NavLink to='/single_page3' onClick={scrollTo()} className='active'>3</NavLink>
                <NavLink to='/single_page4'>4</NavLink>
                <NavLink to='/single_page5'>5</NavLink>
                <NavLink to='/single_page6'>6</NavLink>
                <NavLink to='/single_page7'>7</NavLink>
            </div>
        </div>
    )
}

export default SP3;