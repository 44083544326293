import React from 'react';
import s from './Sample.module.css';
import { NavLink } from 'react-router-dom';

const Semple = (props) => {
    return (
        <NavLink to={props.page + '/' + props.title} className={navLink => navLink.isActive ? s.active : s.disactive }>
        <div className={s.holder}>
            <div className={s.body}>
                <div className={s.item}>
                    <div className={s.photo}>
                        <img src={props.photo} alt={props.title}></img>
                    </div>
                    <div className={s.title}>
                        <span>Зразок: {props.title}</span>
                    </div>
                </div>
                <NavLink className={s.close} to={props.page}></NavLink>
            </div>
        </div>
        </NavLink>
    )
}

export default Semple;