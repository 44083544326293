import { NavLink } from 'react-router-dom';
import './Header.css';
import { scrollTo } from '../../fuction';

const showMenu = () => {
    document.getElementById('burger').classList.toggle('active');
    document.getElementById('navbar').classList.toggle('active');
}

const Header = () => {
    return (
        <header className='header'>
            <div className='container'>
                <div className='header_logo'>
                    <div className='logo_image'>
                        <img src='/img/GK_logo.png' alt='logo'></img>
                    </div>
                    <div className='logo_text'>
                        <span className='text_name'>GranKord</span>
                        <span className='text_slogan'>Гранітні вироби</span>
                    </div>
                </div>
                <div className='header_navbar' id='navbar'>
                    <ul className='header_navbar__list'> 
                        <li><NavLink className='header_navbar__item' to="/" onClick={showMenu + scrollTo()}>Головна</NavLink></li>
                        <li><NavLink className='header_navbar__item' to="/catalog" onClick={showMenu + scrollTo()}>Наші роботи</NavLink></li>
                        {/* <li><NavLink className='header_navbar__item' to="/contact" onClick={showMenu + scrollTo()}>Послуги</NavLink></li> */}
                        <li><NavLink className='header_navbar__item' to="/contact" onClick={showMenu + scrollTo()}>Контакти</NavLink></li>
                    </ul>
                </div>
                <div className='header_call'>
                    <div className='header_call__pc'>
                        <a href='tel:+380680673540'><div className='pc_img'><img src='https://kyivstar-home.com.ua/wp-content/uploads/2019/07/Favicon-Kyivstar.png' alt='Kyivstar'></img></div><span>+380 (68) 067-35-40</span></a>
                    </div>
                    <div className='header_call__phone'>
                        <a href='tel:+380680673540'><img alt='call' src='/img/call.png'></img></a>
                    </div>
                </div>
                <div className='header_burger' id='burger' onClick={showMenu}>
                    <span></span>
                </div>
            </div>
        </header>
    )
}

export default Header;