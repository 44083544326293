import Title from '../holders/Title';
import s from './Contact.module.css'

const Contact = () => {
    return(
        <div className='container'>
            <Title title="Наші контакти" subtitle="З приводу замовлень, питань або пропозицій зв'яжітся з нами з 8:00-22:00"/>
            <div className={s.contact_container}>
                <div class={s.mapouter}>
                    <div class={s.gmap_canvas}>
                        <iframe class={s.gmap_iframe} title='maps' frameborder="0" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=400&amp;height=200&amp;hl=en&amp;q=Korostyshiv&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                    </div>
                </div>
                <div className={s.contacts}>
                    <div className={s.numbers + ' ' + s.contacts_item}>
                        <span className={s.title}>Телефони</span>
                        <a href='tel:+380680673540'>+38 (068) 067-35-40</a>
                    </div>
                    <div className={s.adress + ' ' + s.contacts_item}>
                        <span className={s.title}>Адреса</span>
                        <span>12500, Житомирська обл., Житомирський р-н, м. Коростишів,</span>
                    </div>
                    <div className={s.email + ' ' + s.contacts_item}>
                        <span className={s.title}>Email</span>
                        <a href='mailto:andreykordolevskij@gmail.com'>andreykordolevskij@gmail.com</a>
                    </div>
                    <div className={s.social + ' ' + s.contacts_item}>
                        <span className={s.title}>Месенджери</span>
                        <div className={s.social_holder}>
                            <div className={s.social_item}>
                                <a href='https://t.me/+380680673540' title='Telegram'><img src='./img/telegram_logo.svg' alt='Telegram'></img></a>
                            </div>
                            <div className={s.social_item}>
                                <a href='viber://chat?number=+380680673540' title='Viber'><img src='./img/viber_logo.png' alt='Viber'></img></a>
                            </div>
                            <div className={s.social_item}>

                            </div>
                            <p><button type='button' class='btn-share'>Поділитися</button></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;