import { NavLink } from 'react-router-dom';
import Semple from '../../holders/Sample';
import Title from '../../holders/Title';
import { scrollTo } from '../../../fuction';

let page = '/single_page1'

const SP1 = () => {
    return(
        <div className='container'>
            <Title title="Одинарні пам'ятники" subtitle="Вишукані комплекти одинарних пам'ятників для пам'яті близьких."/>
            <div className='windows_block'>
                <Semple page={page} title="MS0001" photo='/img/single/MS0001.png'/>
                <Semple page={page} title="MS0002" photo='/img/single/MS0002.png'/>
                <Semple page={page} title="MS0003" photo='/img/single/MS0003.png'/>
                <Semple page={page} title="MS0004" photo='/img/single/MS0004.png'/>
                <Semple page={page} title="MS0005" photo='/img/single/MS0005.png'/>
                <Semple page={page} title="MS0006" photo='/img/single/MS0006.png'/>
                <Semple page={page} title="MS0007" photo='/img/single/MS0007.png'/>
                <Semple page={page} title="MS0008" photo='/img/single/MS0008.png'/>
                <Semple page={page} title="MS0009" photo='/img/single/MS0009.png'/>
                <Semple page={page} title="MS0010" photo='/img/single/MS0010.png'/>
                <Semple page={page} title="MS0011" photo='/img/single/MS0011.png'/>
                <Semple page={page} title="MS0012" photo='/img/single/MS0012.png'/>
                <Semple page={page} title="MS0013" photo='/img/single/MS0013.png'/>
                <Semple page={page} title="MS0014" photo='/img/single/MS0014.png'/>
                <Semple page={page} title="MS0015" photo='/img/single/MS0015.png'/>
                <Semple page={page} title="MS0016" photo='/img/single/MS0016.png'/>
                <Semple page={page} title="MS0017" photo='/img/single/MS0017.png'/>
                <Semple page={page} title="MS0018" photo='/img/single/MS0018.png'/>
                <Semple page={page} title="MS0019" photo='/img/single/MS0019.png'/>
                <Semple page={page} title="MS0020" photo='/img/single/MS0020.png'/>
                <Semple page={page} title="MS0021" photo='/img/single/MS0021.png'/>
                <Semple page={page} title="MS0022" photo='/img/single/MS0022.png'/>
                <Semple page={page} title="MS0023" photo='/img/single/MS0023.png'/>
                <Semple page={page} title="MS0024" photo='/img/single/MS0024.png'/>
            </div>
            <div className='windows_navbar'>
                <NavLink to='/single_page1' onClick={scrollTo()} ClassName='active'>1</NavLink>
                <NavLink to='/single_page2'>2</NavLink>
                <NavLink to='/single_page3'>3</NavLink>
                <NavLink to='/single_page4'>4</NavLink>
                <NavLink to='/single_page5'>5</NavLink>
                <NavLink to='/single_page6'>6</NavLink>
                <NavLink to='/single_page7'>7</NavLink>
            </div>
        </div>
    )
}

export default SP1;