import { NavLink } from 'react-router-dom';
import Semple from '../../holders/Sample';
import Title from '../../holders/Title';
import { scrollTo } from '../../../fuction';

let page = '/double_page3'

const DP3 = () => {
    return(
        <div className='container'>
            <Title title="Подвійні пам'ятники" subtitle=""/>
            <div className='windows_block'>
                <Semple page={page} title="MD0049" photo='/img/double/MD0049.png'/>
                <Semple page={page} title="MD0050" photo='/img/double/MD0050.png'/>
                <Semple page={page} title="MD0051" photo='/img/double/MD0051.png'/>
                <Semple page={page} title="MD0052" photo='/img/double/MD0052.png'/>
                <Semple page={page} title="MD0053" photo='/img/double/MD0053.png'/>
                <Semple page={page} title="MD0054" photo='/img/double/MD0054.png'/>
                <Semple page={page} title="MD0055" photo='/img/double/MD0055.png'/>
                <Semple page={page} title="MD0056" photo='/img/double/MD0056.png'/>
                <Semple page={page} title="MD0057" photo='/img/double/MD0057.png'/>
                <Semple page={page} title="MD0058" photo='/img/double/MD0058.png'/>
                <Semple page={page} title="MD0059" photo='/img/double/MD0059.png'/>
                <Semple page={page} title="MD0060" photo='/img/double/MD0060.png'/>
                <Semple page={page} title="MD0061" photo='/img/double/MD0061.png'/>
                <Semple page={page} title="MD0062" photo='/img/double/MD0062.png'/>
                <Semple page={page} title="MD0063" photo='/img/double/MD0063.png'/>
                <Semple page={page} title="MD0064" photo='/img/double/MD0064.png'/>
                <Semple page={page} title="MD0065" photo='/img/double/MD0065.png'/>
                <Semple page={page} title="MD0066" photo='/img/double/MD0066.png'/>
                <Semple page={page} title="MD0067" photo='/img/double/MD0067.png'/>
                <Semple page={page} title="MD0068" photo='/img/double/MD0068.png'/>
                <Semple page={page} title="MD0069" photo='/img/double/MD0069.png'/>
                <Semple page={page} title="MD0070" photo='/img/double/MD0070.png'/>
                <Semple page={page} title="MD0071" photo='/img/double/MD0071.png'/>
                <Semple page={page} title="MD0072" photo='/img/double/MD0072.png'/>
            </div>
            <div className='windows_navbar'>
                <NavLink to='/double_page1'>1</NavLink>
                <NavLink to='/double_page2'>2</NavLink>
                <NavLink to='/double_page3' onClick={scrollTo()} className='active'>3</NavLink>
                <NavLink to='/double_page4'>4</NavLink>
            </div>
        </div>
    )
}

export default DP3;