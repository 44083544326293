import { NavLink } from 'react-router-dom';
import Semple from '../../holders/Sample';
import Title from '../../holders/Title';
import { scrollTo } from '../../../fuction';

let page = '/double_page4'

const DP4 = () => {
    return(
        <div className='container'>
            <Title title="Подвійні пам'ятники" subtitle=""/>
            <div className='windows_block'>
                <Semple page={page} title="MD0073" photo='/img/double/MD0073.png'/>
                <Semple page={page} title="MD0074" photo='/img/double/MD0074.png'/>
                <Semple page={page} title="MD0075" photo='/img/double/MD0075.png'/>
                <Semple page={page} title="MD0076" photo='/img/double/MD0076.png'/>
                <Semple page={page} title="MD0077" photo='/img/double/MD0077.png'/>
            </div>
            <div className='windows_navbar'>
                <NavLink to='/double_page1'>1</NavLink>
                <NavLink to='/double_page2'>2</NavLink>
                <NavLink to='/double_page3'>3</NavLink>
                <NavLink to='/double_page4' onClick={scrollTo()} className='active'>4</NavLink>
            </div>
        </div>
    )
}

export default DP4;