import { NavLink } from 'react-router-dom';
import Semple from '../../holders/Sample';
import Title from '../../holders/Title';
import { scrollTo } from '../../../fuction';

let page = '/red-stone_page1';

const RP1 = () => {
    return(
        <div className='container'>
            <Title title="З червоним каменем" subtitle=""/>
            <div className='windows_block'>
                <Semple page={page} title="MR0001" photo='/img/red_stone/MR0001.png'/>
                <Semple page={page} title="MR0002" photo='/img/red_stone/MR0002.png'/>
                <Semple page={page} title="MR0003" photo='/img/red_stone/MR0003.png'/>
                <Semple page={page} title="MR0004" photo='/img/red_stone/MR0004.png'/>
                <Semple page={page} title="MR0005" photo='/img/red_stone/MR0005.png'/>
                <Semple page={page} title="MR0006" photo='/img/red_stone/MR0006.png'/>
                <Semple page={page} title="MR0007" photo='/img/red_stone/MR0007.png'/>
                <Semple page={page} title="MR0008" photo='/img/red_stone/MR0008.png'/>
                <Semple page={page} title="MR0009" photo='/img/red_stone/MR0009.png'/>
                <Semple page={page} title="MR0010" photo='/img/red_stone/MR0010.png'/>
                <Semple page={page} title="MR0011" photo='/img/red_stone/MR0011.png'/>
                <Semple page={page} title="MR0012" photo='/img/red_stone/MR0012.png'/>
                <Semple page={page} title="MR0013" photo='/img/red_stone/MR0013.png'/>
                <Semple page={page} title="MR0014" photo='/img/red_stone/MR0014.png'/>
                <Semple page={page} title="MR0015" photo='/img/red_stone/MR0015.png'/>
                <Semple page={page} title="MR0016" photo='/img/red_stone/MR0016.png'/>
                <Semple page={page} title="MR0017" photo='/img/red_stone/MR0017.png'/>
                <Semple page={page} title="MR0018" photo='/img/red_stone/MR0018.png'/>
                <Semple page={page} title="MR0019" photo='/img/red_stone/MR0019.png'/>
                <Semple page={page} title="MR0020" photo='/img/red_stone/MR0020.png'/>
                <Semple page={page} title="MR0021" photo='/img/red_stone/MR0021.png'/>
                <Semple page={page} title="MR0022" photo='/img/red_stone/MR0022.png'/>
                <Semple page={page} title="MR0023" photo='/img/red_stone/MR0023.png'/>
                <Semple page={page} title="MR0024" photo='/img/red_stone/MR0024.png'/>
            </div>
            <div className='windows_navbar'>
                <NavLink to='/red-stone_page1' onClick={scrollTo()} className='active'>1</NavLink>
                <NavLink to='/red-stone_page2'>2</NavLink>
            </div>
        </div>
    )
}

export default RP1;