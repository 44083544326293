import { NavLink } from 'react-router-dom';
import Semple from '../../holders/Sample';
import Title from '../../holders/Title';
import { scrollTo } from '../../../fuction';

let page = '/cross_page1'

const CrossP1 = () => {
    return(
        <div className='container'>
            <Title title="Хрести" subtitle=""/>
            <div className='windows_block'>
                <Semple page={page} title="20210126_222642" photo='/img/cross/IMG_20210126_222642.png'/>
                <Semple page={page} title="20210324_163850" photo='/img/cross/IMG_20210324_163850.png'/>
                <Semple page={page} title="20210324_164505" photo='/img/cross/IMG_20210324_164505.png'/>
                <Semple page={page} title="20210324_164836" photo='/img/cross/IMG_20210324_164836.png'/>
                <Semple page={page} title="20210324_170010" photo='/img/cross/IMG_20210324_170010.png'/>
                <Semple page={page} title="20210731_123431" photo='/img/cross/IMG_20210731_123431.png'/>
                <Semple page={page} title="20210827_183005" photo='/img/cross/IMG_20210827_183005.png'/>
                <Semple page={page} title="20220127_114747" photo='/img/cross/IMG_20220127_114747.png'/>
                <Semple page={page} title="20220131_105616" photo='/img/cross/IMG_20220131_105616.png'/>
                <Semple page={page} title="20220203_204457" photo='/img/cross/IMG_20220203_204457.png'/>
                <Semple page={page} title="20220203_204617" photo='/img/cross/IMG_20220203_204617.png'/>
                <Semple page={page} title="20220203_204812" photo='/img/cross/IMG_20220203_204812.png'/>
                <Semple page={page} title="20220519_084452" photo='/img/cross/IMG_20220519_084452.png'/>
                <Semple page={page} title="20230324_144717" photo='/img/cross/IMG_20230324_144717.png'/>
                <Semple page={page} title="c3d5138f6e1d111" photo='/img/cross/IMG-c3d5138f6e1d1115efb8c9c2ee783118-V.png'/>
                <Semple page={page} title="13-00-03-668" photo='/img/cross/Screenshot_2023-04-24-13-00-03-668_com.viber.voip.png'/>
            </div>    
            <div className='windows_navbar'>
                <NavLink to='/cross_page1' onClick={scrollTo()} className='active'>1</NavLink>
            </div>
        </div>
    )
}

export default CrossP1;