import { NavLink } from 'react-router-dom';
import Semple from '../../holders/Sample';
import Title from '../../holders/Title';
import { scrollTo } from '../../../fuction';

let page = '/single_page5';

const SP5 = () => {
    return(
        <div className='container'>
            <Title title="Одинарні пам'ятники" subtitle="Вишукані комплекти одинарних пам'ятників для пам'яті близьких."/>
            <div className='windows_block'>
                <Semple page={page} title="MS0097" photo='/img/single/MS0097.png'/>  
                <Semple page={page} title="MS0098" photo='/img/single/MS0098.png'/>  
                <Semple page={page} title="MS0099" photo='/img/single/MS0099.png'/>  
                <Semple page={page} title="MS0100" photo='/img/single/MS0100.png'/>  
                <Semple page={page} title="MS0101" photo='/img/single/MS0101.png'/>  
                <Semple page={page} title="MS0102" photo='/img/single/MS0102.png'/>  
                <Semple page={page} title="MS0103" photo='/img/single/MS0103.png'/>  
                <Semple page={page} title="MS0104" photo='/img/single/MS0104.png'/>  
                <Semple page={page} title="MS0105" photo='/img/single/MS0105.png'/>  
                <Semple page={page} title="MS0106" photo='/img/single/MS0106.png'/>  
                <Semple page={page} title="MS0107" photo='/img/single/MS0107.png'/>  
                <Semple page={page} title="MS0108" photo='/img/single/MS0108.png'/>  
                <Semple page={page} title="MS0109" photo='/img/single/MS0109.png'/>  
                <Semple page={page} title="MS0110" photo='/img/single/MS0110.png'/>  
                <Semple page={page} title="MS0111" photo='/img/single/MS0111.png'/>  
                <Semple page={page} title="MS0112" photo='/img/single/MS0112.png'/>  
                <Semple page={page} title="MS0113" photo='/img/single/MS0113.png'/>  
                <Semple page={page} title="MS0114" photo='/img/single/MS0114.png'/>  
                <Semple page={page} title="MS0115" photo='/img/single/MS0115.png'/>  
                <Semple page={page} title="MS0116" photo='/img/single/MS0116.png'/>  
                <Semple page={page} title="MS0117" photo='/img/single/MS0117.png'/>  
                <Semple page={page} title="MS0118" photo='/img/single/MS0118.png'/>  
                <Semple page={page} title="MS0119" photo='/img/single/MS0119.png'/>  
                <Semple page={page} title="MS0120" photo='/img/single/MS0120.png'/> 
            </div>
            <div className='windows_navbar'>
                <NavLink to='/single_page1'>1</NavLink>
                <NavLink to='/single_page2'>2</NavLink>
                <NavLink to='/single_page3'>3</NavLink>
                <NavLink to='/single_page4'>4</NavLink>
                <NavLink to='/single_page5' onClick={scrollTo()} className='active'>5</NavLink>
                <NavLink to='/single_page6'>6</NavLink>
                <NavLink to='/single_page7'>7</NavLink>
            </div>
        </div>
    )
}

export default SP5;