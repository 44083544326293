import { NavLink } from 'react-router-dom';
import Semple from '../../holders/Sample';
import Title from '../../holders/Title';
import { scrollTo } from '../../../fuction';

let page = '/red-stone_page2';

const RP2 = () => {
    return(
        <div className='container'>
            <Title title="З червоним каменем" subtitle=""/>
            <div className='windows_block'>
                <Semple page={page} title="MR0025" photo='/img/red_stone/MR0025.png'/>
                <Semple page={page} title="MR0026" photo='/img/red_stone/MR0026.png'/>
                <Semple page={page} title="MR0027" photo='/img/red_stone/MR0027.png'/>
                <Semple page={page} title="MR0028" photo='/img/red_stone/MR0028.png'/>
                <Semple page={page} title="MR0029" photo='/img/red_stone/MR0029.png'/>
                <Semple page={page} title="MR0030" photo='/img/red_stone/MR0030.png'/>
                <Semple page={page} title="MR0031" photo='/img/red_stone/MR0031.png'/>
                <Semple page={page} title="MR0032" photo='/img/red_stone/MR0032.png'/>
                <Semple page={page} title="MR0033" photo='/img/red_stone/MR0033.png'/>
                <Semple page={page} title="MR0034" photo='/img/red_stone/MR0034.png'/>
                <Semple page={page} title="MR0035" photo='/img/red_stone/MR0035.png'/>
                <Semple page={page} title="MR0036" photo='/img/red_stone/MR0036.png'/>
                <Semple page={page} title="MR0037" photo='/img/red_stone/MR0037.png'/>
                <Semple page={page} title="MR0038" photo='/img/red_stone/MR0038.png'/>
                <Semple page={page} title="MR0039" photo='/img/red_stone/MR0039.png'/>
                <Semple page={page} title="MR0040" photo='/img/red_stone/MR0040.png'/>
                <Semple page={page} title="MR0041" photo='/img/red_stone/MR0041.png'/>
                <Semple page={page} title="MR0042" photo='/img/red_stone/MR0042.png'/>
                <Semple page={page} title="MR0043" photo='/img/red_stone/MR0043.png'/>
                <Semple page={page} title="MR0044" photo='/img/red_stone/MR0044.png'/>
                <Semple page={page} title="MR0045" photo='/img/red_stone/MR0045.png'/>
                <Semple page={page} title="MR0046" photo='/img/red_stone/MR0046.png'/>
            </div>
            <div className='windows_navbar'>
                <NavLink to='/red-stone_page1'>1</NavLink>
                <NavLink to='/red-stone_page2' onClick={scrollTo()} className='active'>2</NavLink>
            </div>
        </div>
    )
}

export default RP2;