import { NavLink } from 'react-router-dom';
import Semple from '../../holders/Sample';
import Title from '../../holders/Title';
import { scrollTo } from '../../../fuction';

let page = '/baby_page1'

const CrossP1 = () => {
    return(
        <div className='container'>
            <Title title="Дитячі пам'ятники" subtitle=""/>
            <div className='windows_block'>
                <Semple page={page} title="MB0001" photo='/img/baby/MB0001.png'/>
                <Semple page={page} title="MB0002" photo='/img/baby/MB0002.png'/>
                <Semple page={page} title="MB0003" photo='/img/baby/MB0003.png'/>
                <Semple page={page} title="MB0004" photo='/img/baby/MB0004.png'/>
                <Semple page={page} title="MB0005" photo='/img/baby/MB0005.png'/>
                <Semple page={page} title="MB0007" photo='/img/baby/MB0007.png'/>
                <Semple page={page} title="MB0008" photo='/img/baby/MB0008.png'/>
            </div>
            <div className='windows_navbar'>
                <NavLink to='/baby_page1' onClick={scrollTo()} className='active'>1</NavLink>
            </div>
        </div>
    )
}

export default CrossP1;