import Banner from "../holders/Banner";
import Advantages from "./Advantages";
import Catalog from "./Catalog";

const Main = () => {
    return(
        <div className='container'>
            <Banner />
            <Catalog />
            <Advantages />
        </div>
    )
}

export default Main;